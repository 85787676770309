import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-shift-kpi" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _vm.entity ? _c("div", { staticClass: "header px-5 pt-3 pb-2 mb-0 d-flex justify-content-between align-items-center" }, [_c("div", { staticClass: "d-flex align-items-center flex-wrap w-100" }, [_c("a-icon", { staticClass: "arrow-left pr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("h5", { staticClass: "pr-3 m-0 h5" }, [_vm._v("Shift - " + _vm._s(_vm.entity.shiftName))]), _c("span", { staticClass: "entity-color" }, [_vm._v(_vm._s(_vm._f("moment")(_vm.entity.kpiDate, "DD/MM/YYYY")))])], 1)]) : _vm._e(), _c("div", { staticClass: "p-5" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 24, "xl": 8 } }, [_c("a-card", { style: { height: "100%" } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { key: "shiftID", attrs: { "form-item": "", "data-source": _vm.listShiftTime, "source": "id", "source-label": "shiftDescription", "label": "Shift", "value": _vm.editForm.shiftID, "disabled": "" }, on: { "change": function($event) {
      return _vm.storeValue("shiftID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { key: "kpiDate", attrs: { "form-item": "", "format": "DD/MM/YYYY", "value": _vm.editForm.kpiDate, "rules": "required", "label": "Date", "disabled": "" }, on: { "change": function($event) {
      return _vm.storeValue("kpiDate", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { key: "shiftLengthHours", attrs: { "form-item": "", "value": _vm.editForm.shiftLengthHours, "rules": "required", "label": "Shift Hour" }, on: { "change": function($event) {
      return _vm.storeValue("shiftLengthHours", $event);
    } } })], 1)], 1), _vm.entity ? _c("div", [_c("div", [_vm._v(" Last modified by "), _c("br"), _vm._v(" " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])]) : _vm._e()], 1)], 1), _c("a-col", { attrs: { "lg": 24, "xl": 16 } }, [_c("a-card", [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "totalNumberofPeople", attrs: { "form-item": "", "rules": "required", "label": "People", "value": _vm.editForm.totalNumberofPeople }, on: { "change": function($event) {
      return _vm.storeValue("totalNumberofPeople", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "numberofCrews", attrs: { "form-item": "", "rules": "required", "label": "Crews", "value": _vm.editForm.numberofCrews }, on: { "change": function($event) {
      return _vm.storeValue("numberofCrews", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("number-input", { key: "numberPeopleAbsent", attrs: { "form-item": "", "rules": "required", "label": "Absent", "value": _vm.editForm.numberPeopleAbsent }, on: { "change": function($event) {
      return _vm.storeValue("numberPeopleAbsent", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("number-input", { key: "percentAbsent", attrs: { "form-item": "", "rules": "required", "suffix": "%", "value": _vm.editForm.percentAbsent, "disabled": "", "label": "% Absent" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "totalDowntimeMins", attrs: { "form-item": "", "label": "D/Time Ch/Over", "rules": "required", "value": _vm.editForm.totalDowntimeMins }, on: { "change": function($event) {
      return _vm.storeValue("totalDowntimeMins", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesProduced", attrs: { "form-item": "", "label": "Tonnes Produced", "rules": "required", "value": _vm.editForm.tonnesProduced }, on: { "change": function($event) {
      return _vm.storeValue("tonnesProduced", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesPerHour", attrs: { "form-item": "", "value": _vm.editForm.tonnesPerHour, "rules": "required", "disabled": "", "label": "Tonnes Per Hr" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesPerPerson", attrs: { "form-item": "", "value": _vm.editForm.tonnesPerPerson, "rules": "required", "disabled": "", "label": "Tonnes Per Person" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "actualUnits", attrs: { "form-item": "", "label": "Unit Produced", "rules": "required", "value": _vm.editForm.actualUnits }, on: { "change": function($event) {
      return _vm.storeValue("actualUnits", $event);
    } } })], 1)], 1)], 1)], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("a-divider")], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("a-row", { attrs: { "justify": "end", "type": "flex" } }, [_c("a-button", { attrs: { "ghost": "", "type": "danger" }, on: { "click": _vm.onDeleteShiftKPIClick } }, [_vm._v("Delete")]), _c("a-button", { staticClass: "ml-2", on: { "click": function($event) {
      return _vm.onBack();
    } } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary", "loading": _vm.isSubmitting }, on: { "click": function($event) {
      return handleSubmit(_vm.submit);
    } } }, [_vm._v("Save")])], 1)], 1)], 1)];
  } }]) })], 1)], 1);
};
var staticRenderFns$1 = [];
var EditShiftKpi_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".edit-shift-kpi .header[data-v-3e3dee81]{background:white}.edit-shift-kpi .header .arrow-left[data-v-3e3dee81]{font-size:18px}.edit-shift-kpi .header .entity-color[data-v-3e3dee81]{color:#92a0b0}\n")();
const __vue2_script$1 = {
  name: "CreateShiftKPI",
  inject: ["resourceProps", "area"],
  data() {
    const [shiftKpiProps, shiftTimeProps] = this.resourceProps;
    return {
      apirUrl: "#{VUE_APP_API_URL}#",
      isSubmitting: false,
      shiftKpiProps,
      shiftTimeProps,
      editForm: {},
      entity: {},
      itemsMenu: [
        {
          key: "report",
          title: "Report",
          path: ""
        },
        {
          key: "shift-kpi",
          title: "Shift KPI",
          path: "/line-performance/shift-kpi"
        }
      ]
    };
  },
  computed: {
    listShiftTime() {
      return this.shiftTimeProps.crud.getList() || [];
    }
  },
  watch: {
    "editForm.shiftLengthHours": function() {
      this.calculateTonnesPerHour();
    },
    "editForm.tonnesProduced": function() {
      this.calculateTonnesPerHour();
      this.calculateTonnesPerPerson();
    },
    "editForm.totalNumberofPeople": function() {
      this.calculateTonnesPerPerson();
      this.calculatePercentAbsent();
    },
    "editForm.numberPeopleAbsent": function() {
      this.calculatePercentAbsent();
    }
  },
  async created() {
    this.fetchListShift();
  },
  async mounted() {
    await this.shiftKpiProps.crud.fetchEntity(), this.entity = this.shiftKpiProps.crud.getEntity();
    this.storeValues(this.entity);
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: `${this.entity && this.entity.id}`,
        title: `Shift - ${this.entity && this.entity.shiftName}`,
        path: `/line-performance/shift-kpi/${this.entity && this.entity.id}`
      }
    ];
  },
  methods: {
    fetchListShift() {
      this.shiftTimeProps.crud.deleteFilter("IsInactive");
      this.shiftTimeProps.crud.deletePagination();
      const customQuery = `areaID=${this.area.value}`;
      this.shiftTimeProps.crud.fetchList(false, void 0, true, customQuery, true);
    },
    onBack() {
      this.$router.push(`/line-performance/shift-kpi`);
    },
    async submit() {
      this.isSubmitting = true;
      try {
        await this.shiftKpiProps.crud.submitEntity("replace").then(() => this.$router.push("/line-performance/shift-kpi"));
      } finally {
        this.isSubmitting = false;
      }
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.shiftKpiProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([key, value]) => this.storeValue(key, value));
    },
    onDeleteShiftKPIClick() {
      let icon, okType, title;
      if (this.entity.IsInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        cancelText: "No",
        icon,
        okText: "Yes",
        okType,
        title,
        onOk: this.deleteShiftKPI
      });
    },
    async deleteShiftKPI() {
      await this.shiftKpiProps.crud.submitPartialEntity({
        resourceName: this.shiftKpiProps.resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !this.entity.isInactive
          }
        ]
      });
      this.$router.push("/line-performance/shift-kpi");
    },
    calculateTonnesPerPerson() {
      const tonnesPerPerson = this.editForm.tonnesProduced / this.editForm.totalNumberofPeople;
      if (tonnesPerPerson > 0 && isFinite(tonnesPerPerson))
        this.storeValue("tonnesPerPerson", tonnesPerPerson.toFixed(2));
      else
        this.storeValue("tonnesPerPerson", "");
    },
    calculateTonnesPerHour() {
      const tonnesPerHour = this.editForm.tonnesProduced / this.editForm.shiftLengthHours;
      if (tonnesPerHour > 0 && isFinite(tonnesPerHour))
        this.storeValue("tonnesPerHour", tonnesPerHour.toFixed(2));
      else
        this.storeValue("tonnesPerHour", "");
    },
    calculatePercentAbsent() {
      const percentAbsent = this.editForm.numberPeopleAbsent / (this.editForm.numberPeopleAbsent + this.editForm.totalNumberofPeople) * 100;
      if (!isNaN(percentAbsent) && isFinite(percentAbsent))
        this.storeValue("percentAbsent", percentAbsent.toFixed(2));
      else
        this.storeValue("percentAbsent", "");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "3e3dee81", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditShiftKpi = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-shift-kpi-container" }, [_c("resource", { attrs: { "name": ["line-performance.kpidata", "line-performance.common.shifts"], "api-url": _vm.apiUrl, "redirect-route": "/line-performance/shift-kpi" } }, [_c("edit-shift-kpi")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditShiftKpi
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
